import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { HtmlMouseButtonEventHandler } from '@digital-magic/react-common'
import { OptionalType, hasValue } from '@digital-magic/ts-common-utils'
import { formatDateShort } from '@utils/date-utils'
import { PaginationInfo } from '@api/endpoints/types'
import { CustomerMessage, CustomerMessageId, useDeleteMessage, useGetMessagesPage } from '@api/endpoints/messages'
import { useAdminDefaultErrorHandler } from '@hooks/useAdminDefaultErrorHandler'
import { Clear, Visibility } from '@mui/icons-material'
import { Dialog, DialogActions, DialogContent, DialogTitle, TablePagination } from '@mui/material'
import { Box } from '@mui/system'
import { Button } from '@controls/Button'
import { ButtonWithConfirmation } from '@controls/Button/ButtonWithConfirmation'
import { Table, TableColumn } from '@controls/Table'
import { MessagesStyled } from '@pages/Admin/Messages/styles'

export const Messages: React.FC = () => {
  const { t } = useTranslation()
  const defaultErrorHandler = useAdminDefaultErrorHandler()
  const [messageId, setMessageId] = React.useState<OptionalType<CustomerMessageId>>(undefined)
  const [selectedPage, setSelectedPage] = React.useState(0)
  const getMessagesPage = useGetMessagesPage(selectedPage, { onError: defaultErrorHandler })
  const deleteMessage = useDeleteMessage({ onError: defaultErrorHandler })

  const loading = getMessagesPage.isFetching || deleteMessage.isLoading

  const onDeleteMessage =
    (id: CustomerMessageId) =>
    (confirmResult: boolean): void => {
      if (confirmResult && !loading) {
        deleteMessage.mutate(id)
      }
    }

  const onViewMessage =
    (id: CustomerMessageId): HtmlMouseButtonEventHandler =>
    (e) => {
      e.preventDefault()
      if (!loading) {
        setMessageId(id)
      }
    }

  const onCloseClick: HtmlMouseButtonEventHandler = (e) => {
    e.preventDefault()
    setMessageId(undefined)
  }

  const tableColumns: ReadonlyArray<TableColumn<CustomerMessage>> = [
    {
      key: 'displayName',
      title: t('pages.admin.messages.fields.displayName'),
      sortable: false
    },
    {
      key: 'emailAddress',
      title: t('pages.admin.messages.fields.emailAddress'),
      sortable: false
    },
    {
      key: 'phoneNumber',
      title: t('pages.admin.messages.fields.phoneNumber'),
      sortable: false
    },
    {
      key: 'language',
      title: t('pages.admin.messages.fields.language'),
      sortable: false
    },
    {
      key: 'createdAt',
      title: t('pages.admin.messages.fields.createdAt'),
      sortable: true,
      render: (row) => formatDateShort(row.createdAt)
    },
    {
      key: null,
      title: null,
      render: (row) => (
        <Box display="flex" columnGap={1}>
          <Button size="small" disabled={loading} onClick={onViewMessage(row.id)}>
            <Visibility />
          </Button>
          <ButtonWithConfirmation
            color="error"
            size="small"
            disabled={loading}
            onConfirmResult={onDeleteMessage(row.id)}
            confirmTitle={t('global.consent.delete.title')}
            confirmMessage={t('global.consent.delete.message')}
          >
            <Clear />
          </ButtonWithConfirmation>
        </Box>
      )
    }
  ]

  const tableData: ReadonlyArray<CustomerMessage> = getMessagesPage.data?.items ?? []
  const tablePagination: PaginationInfo = getMessagesPage.data?.pagination ?? {
    totalItems: 0,
    pageSize: 100,
    selectedPage: 0
  }
  const selectedMessage = tableData.find((d) => d.id === messageId)?.message

  const onPageChanged = (page: number): void => {
    setSelectedPage(page)
  }

  return (
    <MessagesStyled>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <h1>{t('pages.admin.messages.title')}</h1>
      </Box>
      <Table columns={tableColumns} data={tableData} initialSortConfig={{ column: 'createdAt', direction: 'DESC' }} />
      <TablePagination
        component="div"
        count={tablePagination.totalItems}
        page={tablePagination.selectedPage}
        rowsPerPage={tablePagination.pageSize}
        rowsPerPageOptions={[tablePagination.pageSize]}
        onPageChange={(_, page) => onPageChanged(page)}
      />
      <Box display="flex" columnGap={2} mt={2}>
        <Button disabled={loading} onClick={() => getMessagesPage.refetch()}>
          {t('global.buttons.refresh')}
        </Button>
      </Box>
      <Dialog open={hasValue(messageId)} onClose={() => setMessageId(undefined)} aria-labelledby="modal-title">
        <DialogTitle>{t('pages.admin.messages.dialog.message.title')}</DialogTitle>
        <DialogContent>
          <Box mx={2}>{selectedMessage}</Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseClick}>{t('global.buttons.close')}</Button>
        </DialogActions>
      </Dialog>
    </MessagesStyled>
  )
}
